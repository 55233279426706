/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.143.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { OrganisationUndOrganisationsBerechtigungRest } from '../models';
// @ts-ignore
import { OrganisationsBerechtigung } from '../models';
// @ts-ignore
import { OrganisationsBerechtigungEditRest } from '../models';
// @ts-ignore
import { PageOrganisationUndOrganisationsBerechtigungRest } from '../models';
/**
 * OrganisationResourceApi - axios parameter creator
 * @export
 */
export const OrganisationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden die Berechtigungen für mehrere Organisationen an einem Verfahren aktualisiert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Aktualisieren der Organisationsberechtigungen eines Verfahrens.
         * @param {Array<OrganisationsBerechtigungEditRest>} organisationsBerechtigungEditRest Liste mit Mappings von Organisation und der Rolle bzw. &lt;code&gt;null&lt;/code&gt; wenn eine Berechtigung entzogen werden soll
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateOrganisationsBerechtigungen: async (organisationsBerechtigungEditRest: Array<OrganisationsBerechtigungEditRest>, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationsBerechtigungEditRest' is not null or undefined
            assertParamExists('addOrUpdateOrganisationsBerechtigungen', 'organisationsBerechtigungEditRest', organisationsBerechtigungEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('addOrUpdateOrganisationsBerechtigungen', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/organisationsberechtigungen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationsBerechtigungEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt wird die Berechtigung von einer Organisation eines Verfahrens entfernt.<br> Es wird gespeichert, wann und vom wem die Berechtigung entzogen wurde.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Entfernen einer Organisationsberechtigung eines Verfahrens.
         * @param {string} planID PlanID des Verfahrens
         * @param {string} organisationID OrgnisationsID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganisationsBerechtigung: async (planID: string, organisationID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteOrganisationsBerechtigung', 'planID', planID)
            // verify required parameter 'organisationID' is not null or undefined
            assertParamExists('deleteOrganisationsBerechtigung', 'organisationID', organisationID)
            const localVarPath = `/verfahren/{planID}/organisationsberechtigung/{organisationID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"organisationID"}}`, encodeURIComponent(String(organisationID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden alle Organisationen ausgeliefert.<br> Und sofern vorhanden die dazugehörige Berechtigung für das Verfahren.<br> Die Ergebnismenge kann über die optionalen Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
         * @summary Endpunkt zum Ausliefern aller Organisationen.
         * @param {string} planID PlanID des Verfahrens
         * @param {string} [organisationName] optional der Name der Organisation (like Suche)
         * @param {number} [rolleID] optional die ID der Berechtigungsrolle                          (0 &#x3D; null &#x3D; keine Einschränkung nach Rollen, -1 &#x3D; Organisationen ohne Berechtigung)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationenMitBerechtigung: async (planID: string, organisationName?: string, rolleID?: number, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getOrganisationenMitBerechtigung', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/organisationen_mit_berechtigung`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organisationName !== undefined) {
                localVarQueryParameter['organisationName'] = organisationName;
            }

            if (rolleID !== undefined) {
                localVarQueryParameter['rolleID'] = rolleID;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden für ein Verfahren die Organisationen mit bestehender oder entzogener Berechtigung ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
         * @summary Endpunkt zum Ausliefern der Organisationsberechtigungen eines Verfahrens.
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorhandeneOrganisationsBerechtigungen: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVorhandeneOrganisationsBerechtigungen', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/vorhandene_organisationsberechtigungen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationResourceApi - functional programming interface
 * @export
 */
export const OrganisationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden die Berechtigungen für mehrere Organisationen an einem Verfahren aktualisiert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Aktualisieren der Organisationsberechtigungen eines Verfahrens.
         * @param {Array<OrganisationsBerechtigungEditRest>} organisationsBerechtigungEditRest Liste mit Mappings von Organisation und der Rolle bzw. &lt;code&gt;null&lt;/code&gt; wenn eine Berechtigung entzogen werden soll
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateOrganisationsBerechtigungen(organisationsBerechtigungEditRest: Array<OrganisationsBerechtigungEditRest>, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationUndOrganisationsBerechtigungRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateOrganisationsBerechtigungen(organisationsBerechtigungEditRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationResourceApi.addOrUpdateOrganisationsBerechtigungen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt wird die Berechtigung von einer Organisation eines Verfahrens entfernt.<br> Es wird gespeichert, wann und vom wem die Berechtigung entzogen wurde.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Entfernen einer Organisationsberechtigung eines Verfahrens.
         * @param {string} planID PlanID des Verfahrens
         * @param {string} organisationID OrgnisationsID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganisationsBerechtigung(planID: string, organisationID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationsBerechtigung>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganisationsBerechtigung(planID, organisationID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationResourceApi.deleteOrganisationsBerechtigung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden alle Organisationen ausgeliefert.<br> Und sofern vorhanden die dazugehörige Berechtigung für das Verfahren.<br> Die Ergebnismenge kann über die optionalen Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
         * @summary Endpunkt zum Ausliefern aller Organisationen.
         * @param {string} planID PlanID des Verfahrens
         * @param {string} [organisationName] optional der Name der Organisation (like Suche)
         * @param {number} [rolleID] optional die ID der Berechtigungsrolle                          (0 &#x3D; null &#x3D; keine Einschränkung nach Rollen, -1 &#x3D; Organisationen ohne Berechtigung)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisationenMitBerechtigung(planID: string, organisationName?: string, rolleID?: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOrganisationUndOrganisationsBerechtigungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationenMitBerechtigung(planID, organisationName, rolleID, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationResourceApi.getOrganisationenMitBerechtigung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden für ein Verfahren die Organisationen mit bestehender oder entzogener Berechtigung ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
         * @summary Endpunkt zum Ausliefern der Organisationsberechtigungen eines Verfahrens.
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVorhandeneOrganisationsBerechtigungen(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationUndOrganisationsBerechtigungRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVorhandeneOrganisationsBerechtigungen(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationResourceApi.getVorhandeneOrganisationsBerechtigungen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganisationResourceApi - factory interface
 * @export
 */
export const OrganisationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden die Berechtigungen für mehrere Organisationen an einem Verfahren aktualisiert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Aktualisieren der Organisationsberechtigungen eines Verfahrens.
         * @param {Array<OrganisationsBerechtigungEditRest>} organisationsBerechtigungEditRest Liste mit Mappings von Organisation und der Rolle bzw. &lt;code&gt;null&lt;/code&gt; wenn eine Berechtigung entzogen werden soll
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateOrganisationsBerechtigungen(organisationsBerechtigungEditRest: Array<OrganisationsBerechtigungEditRest>, planID: string, options?: any): AxiosPromise<Array<OrganisationUndOrganisationsBerechtigungRest>> {
            return localVarFp.addOrUpdateOrganisationsBerechtigungen(organisationsBerechtigungEditRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt wird die Berechtigung von einer Organisation eines Verfahrens entfernt.<br> Es wird gespeichert, wann und vom wem die Berechtigung entzogen wurde.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
         * @summary Endpunkt zum Entfernen einer Organisationsberechtigung eines Verfahrens.
         * @param {string} planID PlanID des Verfahrens
         * @param {string} organisationID OrgnisationsID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganisationsBerechtigung(planID: string, organisationID: string, options?: any): AxiosPromise<OrganisationsBerechtigung> {
            return localVarFp.deleteOrganisationsBerechtigung(planID, organisationID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden alle Organisationen ausgeliefert.<br> Und sofern vorhanden die dazugehörige Berechtigung für das Verfahren.<br> Die Ergebnismenge kann über die optionalen Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
         * @summary Endpunkt zum Ausliefern aller Organisationen.
         * @param {string} planID PlanID des Verfahrens
         * @param {string} [organisationName] optional der Name der Organisation (like Suche)
         * @param {number} [rolleID] optional die ID der Berechtigungsrolle                          (0 &#x3D; null &#x3D; keine Einschränkung nach Rollen, -1 &#x3D; Organisationen ohne Berechtigung)
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationenMitBerechtigung(planID: string, organisationName?: string, rolleID?: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOrganisationUndOrganisationsBerechtigungRest> {
            return localVarFp.getOrganisationenMitBerechtigung(planID, organisationName, rolleID, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden für ein Verfahren die Organisationen mit bestehender oder entzogener Berechtigung ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
         * @summary Endpunkt zum Ausliefern der Organisationsberechtigungen eines Verfahrens.
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVorhandeneOrganisationsBerechtigungen(planID: string, options?: any): AxiosPromise<Array<OrganisationUndOrganisationsBerechtigungRest>> {
            return localVarFp.getVorhandeneOrganisationsBerechtigungen(planID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationResourceApi - object-oriented interface
 * @export
 * @class OrganisationResourceApi
 * @extends {BaseAPI}
 */
export class OrganisationResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden die Berechtigungen für mehrere Organisationen an einem Verfahren aktualisiert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
     * @summary Endpunkt zum Aktualisieren der Organisationsberechtigungen eines Verfahrens.
     * @param {Array<OrganisationsBerechtigungEditRest>} organisationsBerechtigungEditRest Liste mit Mappings von Organisation und der Rolle bzw. &lt;code&gt;null&lt;/code&gt; wenn eine Berechtigung entzogen werden soll
     * @param {string} planID PlanID des Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationResourceApi
     */
    public addOrUpdateOrganisationsBerechtigungen(organisationsBerechtigungEditRest: Array<OrganisationsBerechtigungEditRest>, planID: string, options?: RawAxiosRequestConfig) {
        return OrganisationResourceApiFp(this.configuration).addOrUpdateOrganisationsBerechtigungen(organisationsBerechtigungEditRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p> Mit diesem Endpunkt wird die Berechtigung von einer Organisation eines Verfahrens entfernt.<br> Es wird gespeichert, wann und vom wem die Berechtigung entzogen wurde.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_WRITE or EIGENE_VERFAHREN_BERECHTIGUNG_WRITE or ADMIN</p>
     * @summary Endpunkt zum Entfernen einer Organisationsberechtigung eines Verfahrens.
     * @param {string} planID PlanID des Verfahrens
     * @param {string} organisationID OrgnisationsID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationResourceApi
     */
    public deleteOrganisationsBerechtigung(planID: string, organisationID: string, options?: RawAxiosRequestConfig) {
        return OrganisationResourceApiFp(this.configuration).deleteOrganisationsBerechtigung(planID, organisationID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden alle Organisationen ausgeliefert.<br> Und sofern vorhanden die dazugehörige Berechtigung für das Verfahren.<br> Die Ergebnismenge kann über die optionalen Suchparameter eingegrenzt werden.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
     * @summary Endpunkt zum Ausliefern aller Organisationen.
     * @param {string} planID PlanID des Verfahrens
     * @param {string} [organisationName] optional der Name der Organisation (like Suche)
     * @param {number} [rolleID] optional die ID der Berechtigungsrolle                          (0 &#x3D; null &#x3D; keine Einschränkung nach Rollen, -1 &#x3D; Organisationen ohne Berechtigung)
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationResourceApi
     */
    public getOrganisationenMitBerechtigung(planID: string, organisationName?: string, rolleID?: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return OrganisationResourceApiFp(this.configuration).getOrganisationenMitBerechtigung(planID, organisationName, rolleID, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b><p> Mit diesem Endpunkt werden für ein Verfahren die Organisationen mit bestehender oder entzogener Berechtigung ausgeliefert.</p><p><b>Benötigte Rechte:</b> VERFAHREN_BERECHTIGUNG_READ or EIGENE_VERFAHREN_BERECHTIGUNG_READ or ADMIN</p>
     * @summary Endpunkt zum Ausliefern der Organisationsberechtigungen eines Verfahrens.
     * @param {string} planID PlanID des Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationResourceApi
     */
    public getVorhandeneOrganisationsBerechtigungen(planID: string, options?: RawAxiosRequestConfig) {
        return OrganisationResourceApiFp(this.configuration).getVorhandeneOrganisationsBerechtigungen(planID, options).then((request) => request(this.axios, this.basePath));
    }
}

